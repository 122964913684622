






























































































































































































































































































.smz-base-table.successors-stats-table {
  text-align: center;
  border-collapse: collapse;

  th, td {
    padding: 1px 10px;
    border: 1px solid #b4b4b4;
    border-collapse: collapse;
  }

}

.successors-table-risk-cell-high {
  background-color: #ffe3e3 !important;
}
.successors-table-risk-cell-normal {
  background-color: #fff9e5 !important;
}
.successors-table-risk-cell-low {
  background-color: #e8ffe0 !important;
}
